import React, { useEffect } from 'react';
import { Controller, useController, useForm, useWatch } from 'react-hook-form';
import styles from '../Form/form.module.css';
import stylesError from './form.module.css';

import Input from '../Inputs/Input/Input';
import Button from '../Button/Button';
import AddButton from '../AddButton/AddButton';

import editIcon from '../../assets/editForm.svg';
import backIcon from '../../assets/header/arrowUser.svg';

import { textStyles } from '../../constants/textStyles';
import Hint from '../Hint';

const HookForm = ({
  inputs,
  grid,
  border,
  action,
  onSubmit,
  title,
  style,
  edit,
  handleFormEdit,
  isAddEquipments,
  handleAddEquipments,
  index,
  addCustomItemTitle,
  handleAddCustomItem,
  customData,
  customDataTop,
  backHandler,
  error,
  loading,
  hint,
  subtitle,
  submitButtonFull,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: inputs?.reduce((acc, item) => {
      if (item?.defaultValue) {
        acc[item.name] = item.defaultValue;
      }
      return acc;
    }, {}),
  });

  return (
    <div className={border ? styles.borderWrapper : styles.wrapper} style={style}>
      {title && (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
          <p style={textStyles.formTitle}>
            {title} {hint && <Hint text={hint} />}
          </p>
          {edit && (
            <img style={{ cursor: 'pointer' }} src={editIcon} alt="Icon" role={'button'} onClick={handleFormEdit} />
          )}
          {isAddEquipments && <AddButton text={'Доп. услуга'} onClick={handleAddEquipments} />}
          {subtitle && subtitle()}
        </div>
      )}
      {customDataTop && <div style={{ marginTop: 20 }}>{customDataTop()}</div>}
      <form style={grid} onSubmit={handleSubmit(onSubmit)}>
        {inputs.map((item) => {
          const dependantValue = item.dependency && watch(item.dependency);
          item.value = watch(item.name);

          if (item?.hide && item?.hide(dependantValue)) {
            return null;
          }

          return (
            <div style={item.style}>
              <Controller
                name={item.name}
                control={control}
                render={({ field }) => {
                  return item?.component ? (
                    <div
                      style={{
                        display: item?.removable ? 'grid' : 'block',
                        gridTemplateColumns: item?.removable ? 'auto 55px' : 'auto',
                        alignItems: 'center',
                      }}
                    >
                      <item.component
                        required={item?.rules?.required}
                        error={errors[item.name]}
                        {...field}
                        {...item}
                        dependantValue={dependantValue}
                        onClick={(e) => {
                          if (item?.onClick) {
                            item?.onClick(e, index);
                          }
                        }}
                      />
                      {item?.removable && (
                        <Button
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            item?.onRemove(item?.name);
                          }}
                        >
                          X
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: item?.removable ? 'grid' : 'block',
                        gridTemplateColumns: item?.removable ? 'auto 55px' : 'auto',
                        alignItems: 'center',
                      }}
                    >
                      <Input
                        required={item?.rules?.required}
                        error={errors[item.name]}
                        {...field}
                        {...item}
                        dependantValue={dependantValue}
                      />
                      {item?.removable && (
                        <Button
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            item?.onRemove(item?.name);
                          }}
                        >
                          X
                        </Button>
                      )}
                    </div>
                  );
                }}
                rules={{
                  ...item.rules,
                  validate: item?.rules?.validate ? (value) => item?.rules?.validate(value, getValues) : () => true,
                }}
              />
              {errors[item.name] && (item?.hide ? !item?.hide() : true) && (
                <div className={stylesError.error}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clipPath="url(#clip0_3320_14643)">
                      <path
                        d="M8.00016 14.6666C4.31816 14.6666 1.3335 11.682 1.3335 7.99998C1.3335 4.31798 4.31816 1.33331 8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6668 11.682 11.6822 14.6666 8.00016 14.6666ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41447 13.3335 7.99998C13.3335 6.58549 12.7716 5.22894 11.7714 4.22874C10.7712 3.22855 9.41465 2.66665 8.00016 2.66665C6.58567 2.66665 5.22912 3.22855 4.22893 4.22874C3.22873 5.22894 2.66683 6.58549 2.66683 7.99998C2.66683 9.41447 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3335 9.99998H8.66683V11.3333H7.3335V9.99998ZM7.3335 4.66665H8.66683V8.66665H7.3335V4.66665Z"
                        fill="#EB5757"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3320_14643">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{errors[item.name].message || 'Это поле обязательно для заполнения'}</span>
                </div>
              )}
            </div>
          );
        })}
        {customData && customData()}
        {addCustomItemTitle && (
          <AddButton
            text={addCustomItemTitle}
            onClick={(e) => {
              e.preventDefault();
              handleAddCustomItem && handleAddCustomItem();
            }}
          />
        )}
        {error && (
          <div
            style={{
              fontWeight: 300,
              fontSize: '11px',
              lineHeight: '20px',
              color: '#EB5757',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginTop: '4px',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_3320_14643)">
                <path
                  d="M8.00016 14.6666C4.31816 14.6666 1.3335 11.682 1.3335 7.99998C1.3335 4.31798 4.31816 1.33331 8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6668 11.682 11.6822 14.6666 8.00016 14.6666ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41447 13.3335 7.99998C13.3335 6.58549 12.7716 5.22894 11.7714 4.22874C10.7712 3.22855 9.41465 2.66665 8.00016 2.66665C6.58567 2.66665 5.22912 3.22855 4.22893 4.22874C3.22873 5.22894 2.66683 6.58549 2.66683 7.99998C2.66683 9.41447 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3335 9.99998H8.66683V11.3333H7.3335V9.99998ZM7.3335 4.66665H8.66683V8.66665H7.3335V4.66665Z"
                  fill="#EB5757"
                />
              </g>
              <defs>
                <clipPath id="clip0_3320_14643">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{error}</span>
          </div>
        )}
        <div
          style={{
            gridColumn: '1 / 2',
          }}
        >
          {action && (
            <div style={{ display: 'flex', gap: '20px', flexDirection: submitButtonFull ? 'column' : 'row' }}>
              <Button type="submit" text={action} fill disabled={loading} />
              {backHandler && backHandler()}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default HookForm;
