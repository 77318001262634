import React, { useEffect, useState } from 'react';
import { COMPLECTATIONS, MARKS } from '../../../constants/columns';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { carsOptions } from '../../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import getContainers from '../../../api/cars/getContainers';

// components
import Header from '../../../components/Header/Header';
import IconButton from '../../../components/IconButton/IconButton';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';
import plus from '../../../assets/buttonIcons/plus.svg';
// icons
import icon from '../../../assets/sidebar/cars.svg';
import { useParams } from 'react-router-dom';
import getContainerCard from '../../../api/containers/getContainerCard';
import getComplectations from '../../../api/cars/getComplectations';
const columns = COMPLECTATIONS;

const CarContainersCard = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const init = () => {
    getComplectations({ containerId: id })
      .then((res) => {})
      .catch((error) => {});
    getContainerCard({ id: id }).then((res) => {
      let array = res.data.complectations.map((item) => {
        let opt = [];
        item.complectation_safely.forEach((element) => {
          opt.push(element.title);
        });
        item.complectation_equipment.forEach((element) => {
          opt.push(element.title);
        });

        return { id: item.id, name: item.title, options: opt.join(', ') };
      });
      setData([...array]);
    });
  };

  React.useEffect(() => {
    init();
  }, []);
  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const handleComplectationNavigate = (complectationId) => {
    navigate('/cars/complectations/' + id + '/' + complectationId);
  }

  return (
    <>
      <Header title="Марка и модель" icon={icon} />
      <div style={{ marginTop: 15, display: 'flex', gap: '20px' }}>
        <IconButton
          text={'Добавить комплектацию'}
          icon={plus}
          onClick={() => navigate('/cars/complectations/create?id=' + id)}
        />
        <IconButton
          text={'Редактировать'}
          icon={plus}
          onClick={() => navigate('/cars/containers/' + id + '/edit')}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <h2 style={{ marginBottom: 15 }}>Комплектации</h2>
        <Table table={table} handleNavigate={handleComplectationNavigate}/>
      </div>
    </>
  );
};

export default CarContainersCard;
